import React, {useCallback, useMemo} from 'react';
import {Card, Spin} from "antd";
import {Cell, Legend, Pie, PieChart} from "recharts";
import {useRequest} from "ahooks";
import {CardProps} from "antd/lib/card";
import npiApi from "../../services/api";

interface INpiDashboardSpacePieBoxProps extends CardProps {
    env: 'internal'|'external';
}

interface IServerStatus {
    internal: Record<string, any>,
    external: Record<string, any>,
}

const NpiDashboardSpacePieBox = ({env, ...props}: INpiDashboardSpacePieBoxProps) => {

    const {loading, data: dataServer} = useRequest<IServerStatus, any>(npiApi.internal.dev.debugInfos, {
        cacheKey: "debug-infos"
    });

    const spaceInfo = useMemo<Record<string, any>|undefined>(() => {
        return !!dataServer ? dataServer[env] : undefined;
    }, [dataServer, env])

    const boxUploadPie = useMemo(() => {
        const freeColor = spaceInfo?.percentUsed < 50
            ? "#19c00d" : (spaceInfo?.percentUsed < 85 ? "#ff8400" : "#ff001d")
        return [
            {name: "Other projects", value: spaceInfo?.otherSizeBytes, color: "#b4b4b4", formatSize: spaceInfo?.otherSize},
            {name: "NPI", value: spaceInfo?.projectSizeBytes, color: "#188ffe", formatSize: spaceInfo?.projectSize},
            {name: "Available Space", value: spaceInfo?.freeDiskBytes, color: freeColor, formatSize: spaceInfo?.freeDisk},
        ]
    }, [spaceInfo]);


    const RADIAN = Math.PI / 180;
    const renderLabel = useCallback(({ cx, cy, midAngle, outerRadius, percent, fill, formatSize }:any) => {
        const radius = outerRadius + 20;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return <g>
            <text x={x} y={y} textAnchor={x > cx ? 'start' : 'end'} fill={fill}>{(percent * 100).toFixed(2)}%</text>
            <text x={x} y={y} dy={18} textAnchor={x > cx ? 'start' : 'end'} fill={"#cecece"}>
                {`(${formatSize})`}
            </text>
        </g>
    }, [RADIAN]);

    return <Card loading={loading} title={`${env} - Disk Usage : ${spaceInfo?.percentUsed} %`} headStyle={{textTransform: "capitalize"}} {...props}>
        {!!spaceInfo
            ? <PieChart width={250} height={250}>
                <Pie data={boxUploadPie} dataKey="value" innerRadius={40} outerRadius={60} fill="#8884d8" label={renderLabel} labelLine={true} minAngle={8}>
                    {boxUploadPie.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                </Pie>
                <br/><br/>
                <Legend/>
            </PieChart>
            : <Spin/>
        }
    </Card>;
};

export default NpiDashboardSpacePieBox;