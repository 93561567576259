import axios from "./axios";
import {IKpi} from "../types/kpi";
import {ICondition} from "../types/condition";
import {IShipmentCode, IShipmentFiltersPayload} from "../types/shipment";
import {IFormAnswer, IFormImagesAnswer, IQuestion} from "../types/question";
import {IUser} from "../types/user";
import {IWTag, IWave} from "../types/wave";
import {Key} from "react";
import _ from "lodash";
import {RcFile} from "antd/lib/upload";
import {INpi} from "../types/npi";
import {ITagValue} from "../types/tag";
import IContact from "../types/contact";
import {FormInstance} from "antd";
import {IShipmentByWaveId} from "../components/external/pos-view/tab-shipment";
import {IWavePosImageUpdatePayload} from "../types/wave-pos-image";
import {INpiUploadImage} from "../components/input/image-uploader";

const configResponseBlob = { responseType: 'blob', rawResponse: true };

const npiApi = {
    internal:{
        interface: () => axios.get('internal/interface'),
        npi: {
            detail: (id:number) => axios.get('internal/npi/' + id + '/detail'),
            detailShort: (id:number) => axios.get('internal/npi/' + id + '/detail-short'),
            create: (params:any) => axios.post('internal/npi/create', params),
            save: ({id, ...params}:{id:number}) => axios.post('internal/npi/' + id + '/save', params),
            saveQuestion: ({npi_id, ...params}:IQuestion) => axios.post('internal/npi/' + npi_id + '/save-question', params),
            removeQuestion: ({id, npi_id}:IQuestion) => axios.get('internal/npi/' + npi_id + '/remove-question/' + id),
            list: (params:any) => axios.post('internal/npi/list', params),
            listWaves: ({id, ...params}:{id:number}) => axios.post('internal/npi/'+id+'/waves', params),
            search: (params:any) => axios.post('internal/npi/search', params),
            changeStatus: ({id, status}:INpi) => axios.post('internal/npi/change-status', {id, status}),
            archive: ({id}:INpi) => axios.get('internal/npi/archive/' + id),
            delete: ({id}:INpi) => axios.get('internal/npi/delete/' + id),
        },
        wave: {
            detail: (id:number) => axios.get('internal/wave/' + id),
            links: (id:number) => axios.get('internal/wave/' + id + '/links'),
            resetLinks: (id:number) => axios.get('internal/wave/' + id + '/reset-links'),
            dailyHistory: ({daily_group_id, ...params}:{daily_group_id:number}) => axios.post('internal/wave/' + daily_group_id + '/daily-history', params),
            dailyCopy: (daily_group_id:number) => axios.get('internal/wave/' + daily_group_id + '/daily-copy'),
            create: (params:any) => axios.post('internal/wave/create', params),
            save: ({id, ...params}:{id:number}) => axios.post('internal/wave/' + id + '/save', params),
            saveQuestion: ({wave_id, ...params}:IQuestion) => axios.post('internal/wave/' + wave_id + '/save-question', params),
            replaceQuestionsByWaveId: (wave_id: number, sourceWaveId: number) => axios.post(`internal/wave/${wave_id}/replace-questions-by-wave`, {sourceWaveId}),
            removeQuestion: ({id, wave_id}:IQuestion) => axios.get('internal/wave/' + wave_id + '/remove-question/' + id),
            listPos: ({waveId, ...params}:{waveId:number}) => axios.post('internal/wave/' + waveId + '/pos', params),
            reportPos: ({waveId, ...params}:{waveId:number}) => axios.post('internal/wave/' + waveId + '/report-pos', params),
            launch: ({id}:{id:number}) : Promise<{wave:IWave, counts:Record<string, number>}> => axios.get('internal/wave/' + id + '/launch'),
            close: ({id}:{id:number}) : Promise<{wave:IWave, counts:Record<string, number>}> => axios.get('internal/wave/' + id + '/close'),
            archive: ({id}:{id:number}) : Promise<{wave:IWave, counts:Record<string, number>}> => axios.get('internal/wave/' + id + '/archive'),
            delete: ({id}:{id:number}) : Promise<{wave:IWave, counts:Record<string, number>}> => axios.get('internal/wave/' + id + '/delete'),
            notify: ({id, contacts}:{id:number, contacts:number[]}) => axios.post('internal/wave/' + id + '/notify', {contacts}),
            previewNotification: (id: number, mail_reception_address : string, mail_payload: any) => axios.post('internal/wave/' + id + '/notify/preview', {mail_reception_address, mail_payload}),
            searchPos: ({wave_id, ...params}:{wave_id:number}) => axios.post('internal/wave/' + wave_id + '/search-pos', params),
            modifyPos: ({wave_id, addedSfoIds, deletedAppleIds}:{wave_id: number, addedSfoIds:Key[], deletedAppleIds:Key[]}) => axios.post('internal/wave/' + wave_id + '/modify-pos', {addedSfoIds, deletedAppleIds}),
            resetPos: ({wave_id, resetAppleIds}:{wave_id: number, resetAppleIds:Key[]}) => axios.post('internal/wave/' + wave_id + '/reset-pos', {resetAppleIds}),
            overlapDetails: ({wave_id, pos_id}:{wave_id:number, pos_id:number}) => axios.post('internal/wave/' + wave_id + '/overlap-details', {pos_id}),
            simulateStatus: ({conditions, questionsIds, answers, tags, fields}:{conditions:ICondition[], questionsIds: number[], answers: IFormAnswer[], tags?: ITagValue, fields?:any}) => axios.post('internal/wave/simulate-status', {answers, conditions, questionsIds, tags, fields}),
            saveMultiPosAnswers: ({wave_id, answers, pos_ids, tags}:{wave_id:number, answers:IFormAnswer[], pos_ids: number[], tags:IWTag[]}) => axios.post('internal/wave/' + wave_id + '/save-multi-pos-answers', {answers, pos_ids, tags}),
            search: (params:any) => axios.post('internal/wave/search', params),
            delayedLaunch: ({id, delayedLaunch}:{id: number, delayedLaunch:any}) => axios.post('internal/wave/' + id + '/delayed-launch', {delayedLaunch}),
            cancelDelayedLaunch: (id:number) => axios.get('internal/wave/' + id + '/cancel-delayed-launch'),
            getWavesAsTree: (params:any) => axios.post('internal/wave/get-as-tree', params),
            updateAuditedPos: ({wave_id, pos_id, ...params}:{wave_id:number, pos_id:number}) => axios.post('internal/wave/' + wave_id + '/update-audited', {pos_id, ...params}),
        },
        question: {
            search: (params:any) => axios.post('internal/question/search', params),
            searchSelect: (params:any) => axios.post('internal/question/search-select', params),
            move: ({id}:IQuestion, newIndex:number) => axios.post('internal/question/move/' + id, {newPos: newIndex}),
        },
        wavepos:{
            search: (params:any) => axios.post('internal/wavepos/search', params),
            save: (params:any) => axios.post('internal/wavepos/save', params),
            bulkReschedule: (params: {waveId: number, filters: any, reschedule: any}) => axios.post('internal/wavepos/bulk-reschedule', params),
            partners: (params: {regionId: number, countries: number[]}) => axios.post('internal/wavepos/partners', params),
            detail: (id:number) => axios.get('internal/wavepos/' + id),
            shipments: ({id, ...params}:{id:number}) => axios.post('internal/wavepos/' + id + '/shipments', params),
            detailWP: (wave_id: number, pos_id: number) => axios.get('internal/wavepos/' + wave_id + '/' + pos_id)
        },
        tag:{
            list: (params:{regionId?:number, waves?:number[], npis?: number[]}) => axios.post('internal/tag/list', params),
            listPos: (params:any) => axios.post('internal/tag/list-pos', params),
            values: ({tagId, waveIds, npiIds}:{tagId:number, waveIds?:number[], npiIds?:number[]}) => axios.post('internal/tag/values', {tagId, waveIds, npiIds}),
            delete: ({id, ...params}:{id:number}) => axios.post('internal/tag/delete/' + id, params),
            updateConfig: ({id, config}:{id:number|undefined, config:any}) => axios.post('internal/tag/update-config/' + id, {config}),
            updateWave: ({id}:{id:number}) => axios.get('internal/tag/update-wave/' + id),
            updateTagPosValue: (posId: number, tagId: number, value: any) => axios.post(`internal/tag/update-tag-pos-value/${posId}`, {tagId,value}),
            buildTagsFile: (params: any) => axios.post('internal/tag/build-tags-file', makeFormData(params), {headers: {'Content-Type': 'multipart/form-data', ...configResponseBlob}}),
        },
        kpi:{
            save: (kpi:IKpi) => axios.post('internal/kpi/save', kpi),
            delete: (kpi:IKpi) => axios.post('internal/kpi/delete', kpi),
            waveSave: (wave_id:number, kpi_id:number, conditions:ICondition, conditions_not_applicable: ICondition) => axios.post('internal/kpi/wave/save', {wave_id, kpi_id, conditions, conditions_not_applicable}),
            npiSave: (npi_id:number, kpi_id:number, conditions:ICondition, conditions_not_applicable: ICondition) => axios.post('internal/kpi/npi/save', {npi_id, kpi_id, conditions, conditions_not_applicable}),
        },
        kpiDashboard:{
            compute: (params:{wavesIds?:number[], npiId?:number, dashboardId?:number, advancedFilters: {}, firstCall?: boolean}) => axios.post('internal/kpi-dashboard/compute', params),
            save: (params:any) => axios.post('internal/kpi-dashboard/save', params),
        },
        wwDashboard:{
            list: (params:any) => axios.post('internal/ww-dashboard/list', params),
            create: ({name, npi_ids}:any) => axios.post('internal/ww-dashboard/create', {name, npi_ids}),
            compute: (npiId:number, params:any) => axios.post('internal/ww-dashboard/compute/' + npiId, params),
            save: (npiId:number, params:any) => axios.post('internal/ww-dashboard/save/' + npiId, params),
        },
        user:{
            list: (params:any) => axios.post('internal/user/list', params),
            detail: (id:number) => axios.get('internal/user/' + id),
            save: (user:IUser) => axios.post('internal/user/'+user.id+'/save', user),
            listLDAP: () =>  axios.get('internal/user/listLDAP'),
            saveFromLDAP: (params:any) => axios.post('internal/user/save-from-ldap', params),
            refresh: (id:number) => axios.post('internal/user/'+id+'/refresh'),
            delete: (user:IUser) => axios.post('internal/user/'+user.id+'/delete'),
            savePreferences: (user:IUser) => axios.post('internal/user/'+user.id+'/save-preferences', {user}),
        },
        shipments: {
            list: (params: any, pageId: number) : Promise<any>  => axios.post(`internal/shipments/paginated-shipments?page=${pageId}`, params),
            delete: (params: any) : Promise<any> => axios.post(`internal/shipments/delete-shipment-internal`, params),
            getFilters :  (params: IShipmentFiltersPayload) : Promise<any>  => axios.post('internal/shipments/computed-dropdowns', params),
            updateFromInternal: ({id, ...params}:{id:number}) : Promise<any> => axios.post(`internal/shipments/${id}/update-from-internal`, params),
            kpis:  (params: IShipmentFiltersPayload) : Promise<any>  => axios.post('internal/shipments/kpis', params),
            code: {
                list: (params:{region_id?:number|null, with_archived?:boolean}) : Promise<any>  => axios.post(`internal/shipments/code/list`, params),
                createShipmentCode : (params: IShipmentCode) : Promise<any> => axios.post('internal/shipments/code/create', params),
                archivedShipmentCode : ({id, is_archived}:{id:number, is_archived:boolean}) : Promise<any> => axios.post(`internal/shipments/code/archived/${id}`, {is_archived: is_archived}),
            },
            templates: {
                list: () => axios.get('internal/shipments/templates/list'),
                create: (params: any) => axios.post('internal/shipments/templates/create', params),
                update: (id:number, params:any) => axios.post('internal/shipments/templates/'+id+'/update', params),
                delete: (id:number) => axios.post('internal/shipments/templates/'+id+'/delete'),
            },
            carrier: {
                list: (params?:{region_id?:number}) => axios.post('internal/shipments/carrier/list', params),
                create: (params: any) => axios.post('internal/shipments/carrier/create', params),
                update: (id:number, params:any) => axios.post('internal/shipments/carrier/'+id+'/update', params),
                delete: (id:number) => axios.post('internal/shipments/carrier/'+id+'/delete'),
            },
            helpers: () : Promise<any>  => axios.get('internal/shipments/helpers'),
        },
        battery: {
            chart: ({id, ...params}:{id:number, date: any, group: any, countries: number[]}) => axios.post(`internal/battery/chart/${id}`, params),
        },
        tableau: {
            information: () => axios.get(`internal/tableau/information`),
        },
        file: {
            exportWavePos: (params: any): Promise<any> => axios.post(`internal/file/export/wave-pos`, params, configResponseBlob),
            exportWavePosReport: (params: any): Promise<any> => axios.post(`internal/file/export/wave-pos-report`, params, configResponseBlob),
            exportWavePosKpis: (params: any): Promise<any> => axios.post(`internal/file/export/wave-pos-kpis`, params, configResponseBlob),
            exportTranslations: (params: any): Promise<any> => axios.post(`internal/file/export/translations`, params, configResponseBlob),
            exportPosTags: (params: any): Promise<any> => axios.post(`internal/file/export/pos-tags`, params, configResponseBlob),
            exportPosLinks: (params: any): Promise<any> => axios.post(`internal/file/export/pos-links`, params, configResponseBlob),
            exportCountryLinks: (params: any): Promise<any> => axios.post(`internal/file/export/country-links`, params, configResponseBlob),
            exportShipments: (params: any): Promise<any> => axios.post(`internal/file/export/shipments`, params, configResponseBlob),
            exportShipmentExample: (params: any): Promise<any> => axios.post(`internal/file/export/shipment-example`, params, configResponseBlob),
            exportSynchronizationChecker: (params: any): Promise<any> => axios.post(`internal/file/export/synchronization-checker`, params, configResponseBlob),
            exportWavePosMerchandisingGroups: (params: any): Promise<any> => axios.post(`internal/file/export/wave-pos-merchandising-groups`, params, configResponseBlob),
            exportWaveTree: (params: any): Promise<any> => axios.post(`internal/file/export/wave-tree`, params, configResponseBlob),
            exportWwDashboardBulk: (params: any): Promise<any> => axios.post(`internal/file/export/ww-dashboard-bulk`, params, configResponseBlob),
            exportWwDashboardPartnerReadiness: (params: any): Promise<any> => axios.post(`internal/file/export/ww-dashboard-partner-readiness`, params, configResponseBlob),
            exportKpiDashboard: (params: any): Promise<any> => axios.post(`internal/file/export/kpi-dashboard`, params, configResponseBlob),
            exportDevTranslationSelection: (params: any): Promise<any> => axios.post(`internal/file/export/dev-translation-selection`, params, configResponseBlob),
            importPosTags: (params: any): Promise<any> => axios.post(`internal/file/import/pos-tags`, makeFormData(params)),
            importWavePosKpis: (params: any): Promise<any> => axios.post(`internal/file/import/wave-pos-kpis`, makeFormData(params)),
            importQuestionTranslationsWave: (params: any): Promise<any> => axios.post(`internal/file/import/question-translations-wave`, makeFormData(params)),
            importQuestionTranslationsNpi: (params: any): Promise<any> => axios.post(`internal/file/import/question-translations-npi`, makeFormData(params)),
            importWavePosMerchandisingGroups: (params: any): Promise<any> => axios.post(`internal/file/import/wave-pos-merchandising-groups`, makeFormData(params)),
            importWwDashboardBulk: (params: any): Promise<any> => axios.post(`internal/file/import/ww-dashboard-bulk`, makeFormData(params)),
            importDevPartialTranslation: (params: any): Promise<any> => axios.post(`internal/file/import/dev-partial-translation`, makeFormData(params)),
        },
        security: {
            logout: () => axios.get('internal/logout'),
            logAsUser: (id: number) => axios.get('internal/login-as-user/' + id),
        },
        contact: {
            list: (): Promise<IContact[]> => axios.get('internal/contact/list'),
            save: (params:any) => axios.post('internal/contact/save', params),
            delete: (contactId:number) => axios.get('internal/contact/delete/' + contactId),
            searchPos: (params: any) => axios.post('internal/contact/search', params)
        },
        gallery: {
            listImages: (params: any) => axios.post('internal/gallery/list', params),
            lastNpiWithImage: () => axios.get(`internal/gallery/last-npi-with-image`),
            // call to external server
            downloadZip: (token:string, params: any) => axios.post(process.env.REACT_APP_EXTERNAL_SERVER_ROOT+'external/image/download-zip', params, {headers: {token}, ...configResponseBlob}),
        },
        translation: {
            translateFromExisting: ({terms, translationIds}: any) => axios.post('internal/translation/translate-from-existing', {terms, translationIds}),
        },
        pos: {
            resetLinks: (id:number) => axios.get('internal/pos/' + id + '/reset-links'),
            list: (params:any) => axios.post('internal/pos/list', params),
        },
        country: {
            resetLinks: (id:number) => axios.get('internal/country/' + id + '/reset-links'),
            list: (params:any) => axios.post('internal/country/list', params),
        },
        dev: {
            boxUpload: {
                status: (params:any) => axios.post('internal/dev-dashboard/box-upload/status', params),
            },
            debugInfos: (params:any) => axios.get('internal/dev-dashboard/debug-infos', params),
            translation: {
                languages: (params:any) => axios.get('internal/dev-dashboard/translation/languages', params),
                copySelectionTo: (params:any) => axios.post('internal/dev-dashboard/translation/copy-selection-to', params),
                fillDefault: (params:any) => axios.post('internal/dev-dashboard/translation/fill-default', params),
                clearDeprecated: (params:any) => axios.post('internal/dev-dashboard/translation/clear-deprecated', params),
                moveKeyTo: (params:any) => axios.post('internal/dev-dashboard/translation/move-key-to', params),
                addNewKey: (params:any) => axios.post('internal/dev-dashboard/translation/add-new-key', params),
                updateKey: (params:any) => axios.post('internal/dev-dashboard/translation/update-key', params),
            },
            synchro: {
                logs: (params:any) => axios.post('internal/dev-dashboard/synchro/logs', params),
                resyncPos: (params:any) => axios.post('internal/dev-dashboard/synchro/resynchronize-wave-pos', params),
                listNpisWaves: (params:any) => axios.post('internal/dev-dashboard/synchro/wave/list', params),
                status: {
                    details: (params:any) => axios.post('internal/dev-dashboard/synchro/status/details', params),
                    waveRelatedTables: (params:any) => axios.post('internal/dev-dashboard/synchro/status/wave-related-tables', params),
                    otherTables: (params:any) => axios.post('internal/dev-dashboard/synchro/status/other-tables', params),
                    wave: (id:number, params:any) => axios.post('internal/dev-dashboard/synchro/status/wave/'+id, params),
                    wavePos: (params:any) => axios.post('internal/dev-dashboard/synchro/status/wave-pos', params),
                    wavePosAnswer: (params:any) => axios.post('internal/dev-dashboard/synchro/status/wave-pos-answers', params),
                },
            },
        }
    },

    external: {
        getInterface: (token: string) => axios.get('external/interface', {headers: {token}}),
        getPosList: (page: number = 1) => axios.get(`external/pos-list/${page}`),
        getSearchPosByTerm: (term: string) => axios.post(`external/pos-search`, {term}),
        getPosDetail: (posId: number) => axios.get(`external/pos/${posId}/details`),
        saveSurveysChanges: (posId: number, answers: IFormAnswer[]) => axios.post(`external/pos/${posId}/surveys`, {answers}),
        saveShipmentsChange: (posId: number, shipments: IShipmentByWaveId) => axios.post(`external/pos/${posId}/shipments`, {shipments}),
        image: {
            uploadPosWaveImage: (posId: number, waveId: number, files: INpiUploadImage[]) => {
                const formData = new FormData();

                //map all file inside formData images array
                for(const file of files){
                    formData.append("images[]", file as RcFile);
                }

                return axios.post(`/external/image/upload/${waveId}/${posId}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            },
            uploadSurveyQuestionImages: (posId: number, files: IFormImagesAnswer[], waveIds: number[]): Promise<any> => {
                const formData = new FormData();

                // append each waveIds
                waveIds.forEach( (wId:any) => formData.append("waveIds[]", wId));

                // map all files per questionId and get existing wavePosImage Ids to keep
                Object.values(files).forEach( (imAns: IFormImagesAnswer) => {
                    const qId = imAns.question_id;
                    imAns.images.forEach( (im:any) => {
                        if( !im.wpiId ){
                            formData.append("images["+qId+"][]", im);
                        } else {
                            formData.append("wpiIds["+qId+"][]", im.wpiId);
                        }
                    })
                });

                return axios.post(`/external/image/upload-question-images/${posId}`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
            },
            editWavePosImage: (wpiId: number, payload: IWavePosImageUpdatePayload) => axios.post(`external/image/edit/${wpiId}`, payload),
            getImageBase64FromRoute: (imageRoute: string) => axios.get(imageRoute, {responseType: 'arraybuffer'})
                .then((resource: any) => Buffer.from(resource).toString('base64')) //convert binary to base64
        },
    }
};

//Make a form data for uploading files
const makeFormData = (data:any) => {
    const formData = new FormData();
    _.forEach(data, (value, key) => {
        if( key !== undefined && value !== undefined ){
            formData.append(key, value)
        }
    });
    return formData;
};

export const dowloadFromBlob = (blob:Blob, filename: string, addToDom=false) => {
    const a = document.createElement('a');
    a.setAttribute('download', filename);
    a.setAttribute('href', window.URL.createObjectURL(blob));
    a.click(); // EXECUTING CLICK EVENT WILL AUTO-DOWNLOAD THE FILE
    a.remove();
};

//Call this in the "then" to automatically download a file received
export const npiApiDownloadFileCallback = (filename:string, type: string = "text/html") => (response:any) => {
    const file = new Blob( [response.data], {type});
    dowloadFromBlob(file, filename);
    return response;
};

//Call this in the "then" to automatically download a file in base64 received
export interface IBase64WithInfo {
    b64:string,
    mime:string,
    filename:string,
    extension:string,
}
export const npiApiDownloadBase64Callback = ({b64, mime, filename}: IBase64WithInfo) => {
    return fetch(`data:${mime};base64,${b64}`)
        .then(res => res.blob())
        .then(blob => dowloadFromBlob(blob, filename, true));
};

export const npiFillFormErrors = (form:FormInstance) => (error:any) => {
    if( error.status === 422 && error.data.errors !== undefined ) {
        const fieldsValue = form.getFieldsValue();
        form.resetFields();
        form.setFieldsValue(fieldsValue);
        form.setFields(Object.entries(error.data.errors).map(([key, value]) => ({name: key, errors: value as string[]})));
    }
}

export default npiApi;
