import React, {useMemo} from 'react';
import {Card} from "antd";
import {Cell, Legend, Pie, PieChart} from "recharts";
import {useTranslation} from "react-i18next";
import {useRequest} from "ahooks";
import {CardProps} from "antd/lib/card";
import npiApi from "../../services/api";

export interface IBoxUploadStatus {
    total: number,
    pending: number,
    unsync: number,
    errors: number,
    uploaded: number,
}

const NpiDashboardSyncPieBoxExport = (props: CardProps) => {

    const {t} = useTranslation();

    const {loading, data:status} = useRequest<IBoxUploadStatus, any>(npiApi.internal.dev.boxUpload.status);

    const boxUploadPie = useMemo(() => [
        {name: t('INTERNAL.CLIENT.COMMON.UPLOADED'), value: status?.uploaded, color: "#19c00d"},
        {name: t('INTERNAL.CLIENT.COMMON.PENDING'), value: status?.pending, color: "#A6ADB4"},
        {name: t('INTERNAL.CLIENT.COMMON.SYNC_ERRORS'), value: status?.unsync, color: "#FF8400"},
        {name: t('INTERNAL.CLIENT.COMMON.ERRORS'), value: status?.errors, color: "#ef000c"},
    ], [status, t]);

    const boxUploadPercent = useMemo(() => {
        if( !status ) {
            return 0;
        }
        const percentUpload = status.uploaded * 100 / status.total;
        return parseFloat(percentUpload.toFixed(2));
    }, [status]);

    return <Card loading={loading} title={t('INTERNAL.CLIENT.DASHBOARD.BOX_UPLOADED_IMAGES', {percent:boxUploadPercent})+" *"} {...props}>
        <div style={{marginBottom: "15px", marginTop: "-20px", textAlign: "right"}}>
            <em>* for archived NPIs</em>
        </div>
        <PieChart width={250} height={230}>
            <Pie data={boxUploadPie} dataKey="value" innerRadius={40} outerRadius={60} fill="#8884d8" label={true} labelLine={true} minAngle={8}>
                {boxUploadPie.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
            </Pie>
            <br/><br/>
            <Legend/>
        </PieChart>
    </Card>;
};

export default NpiDashboardSyncPieBoxExport;